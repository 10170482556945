<template>
  <div>
    <div v-if="!isMobile">
      <el-dialog :title="title"
                 :visible.sync="centerDialogVisible2"
                 center
                 class="way-dialog"
                 :before-close="handleClose"
                 :close-on-click-modal="false"
                 :close-on-press-escape="false">
        <div class="pay-hot">
          <i><img src="@/assets/images/icon/hot.png"></i>
          产品新功能限时特惠
        </div>
        <div class="pay-title">
          <img alt="" src="@/assets/images/icon/order.png" />
          <h3>支付订单</h3>
        </div>
        <div class="pay-cont" v-loading="payLoading">
          <div class="pay-img" v-if="!payBtn">
            <div v-if="dialogOrderDetail.qrCode">
              <generateQRCode :url="dialogOrderDetail.qrCode"></generateQRCode>
            </div>
          </div>
          <div class="pay-text">
            <div class="price-box" :class="{'show-jcc' : payBtn}">
              <div class="price-left">
                <div>
                  总金额：
                  <span>{{ dialogOrderDetail.price }}</span>
                  <span class="t">元</span>
                  <span class="ck">
                  <el-checkbox v-model="payChecked"
                               :checked="payChecked"
                               :text-color="'#3c3c3c'"
                               @change="changeCheckbox">智慧豆抵扣</el-checkbox>
                  <span> {{ userSetInfo && userSetInfo.balance >= 0 ? userSetInfo.balance + '元' : '未登录' }}</span>
                </span>
                </div>
                <div class="price-foot">
                  (
                  <span class="yj">原价：{{ dialogOrderDetail.showPrice }}元</span>
                  <span class="yh">共优惠{{ preferentialPrice }}元</span>
                  )
                  <span class="cz" v-if="userInfo.isLogin" @click="openRecharge">
                  <i class="qian16">￥</i>
                  充值越多越划算
                </span>
                  <span class="cz" v-else @click="openLogin">
                  <i class="qian16">￥</i>
                  智慧豆支付更划算
                </span>
                </div>
              </div>
            </div>
            <div class="pay-tip" v-if="!payBtn">
              <img alt="" src="@/assets/images/icon/wx-pay2.png">
              <img alt="" src="@/assets/images/icon/zfb-pay2.png">
              <p>使用微信、支付宝扫码支付<span>(扫码支付,请勿刷新页面)</span></p>
            </div>
            <div class="pay-btn2" v-if="payBtn">
              <el-button type="primary" @click="postBalancePay">立即支付</el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <div v-else>
      <el-drawer class="show-drawer-box"
                 title="支付订单"
                 :with-header="false"
                 :visible.sync="centerDialogVisible2"
                 :before-close="handleClose"
                 :show-close="true"
                 :direction="'btt'">
        <div class="show-drawer-item" v-loading="payLoading">
          <div class="pay-title">
            <img src="@/assets/images/icon/order.png" alt="" />
            <h3>支付订单</h3>
          </div>
          <div class="pay-h2">{{ title }}</div>
          <div class="pay-check" v-if="!payBtn">
            <div v-if="!isWeChatBrowser()">
              <img alt="" src="@/assets/images/icon/zfb-pay2.png">
              <span>支付宝支付</span>
              <el-radio class="pay-radio" v-model="payMethod" label="1">{{""}}</el-radio>
            </div>
            <div>
              <img alt="" src="@/assets/images/icon/wx-pay2.png">
              <span>微信支付</span>
              <el-radio class="pay-radio" v-model="payMethod" label="2">{{""}}</el-radio>
            </div>
          </div>
          <div class="txt-h5-ck">
            <el-checkbox v-model="payChecked"
                         :checked="payChecked"
                         :text-color="'#3c3c3c'"
                         @change="changeCheckbox">智慧豆抵扣</el-checkbox>
            <span class="txt-h5-p"> {{ userSetInfo && userSetInfo.balance >= 0 ? userSetInfo.balance + '元' : '未登录' }}</span>
            <span class="cz" v-if="userInfo.isLogin" @click="openRecharge">
            <i class="qian16">￥</i>
            充值越多越划算
          </span>
            <span class="cz" v-else @click="openLogin">
            <i class="qian16">￥</i>
            智慧豆支付更划算
          </span>
          </div>
          <div class="pay-btn">
            <div class="pay-txt">
              总金额：
              <span>{{dialogOrderDetail.price}}元</span>
              <i>{{dialogOrderDetail.showPrice}}元</i>
            </div>
            <el-button type="primary" size="small" @click="openPay">确认支付</el-button>
          </div>
        </div>
      </el-drawer>
    </div>

    <WalletBalance ref="Wallet" @userSetInfo="getUserSetInfo"></WalletBalance>
    <Login ref="showLogin"/>
  </div>
</template>
<script>
import CommonApi from "@/api/common"
import generateQRCode from "@/components/generateQRCode/generateQRCode.vue"
import WalletBalance from "@/components/walletBalance/index.vue"
import Login from "@/components/login/login.vue"
import UserPromotApi from "@/api/user"
import { EventBus } from "@/utils/eventBus"
export default {
  components: {Login, WalletBalance, generateQRCode},
  data() {
    return {
      centerDialogVisible2: false,
      dialogOrderDetail: '',
      title: '',
      payMethod: '1',
      userInfo: {
        isLogin: false,
        username: '',
        nickname: '',
        token: ''
      },
      userSetInfo: '',
      payChecked: false,
      payBtn: false,
      payLoading: false,
      windowWidth: document.body.clientWidth,
      isMobile: false
    }
  },
  created() {
    this.isMobile = this.windowWidth < 901;
    if(localStorage.getItem('userInfo')){
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    }
    if(localStorage.getItem('userSetInfo')){
      this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
    }
  },
  watch: {
    // 监听页面宽度
    windowWidth(newWidth) {
      this.isMobile = newWidth < 901;
    }
  },
  mounted() {
    EventBus.$on("userLogin", (msg) => {
      // 登录成功发送来的消息
      if(msg === 'userLoginTrue'){
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
      }
    })
    EventBus.$on("userOutLogin", (msg) => {
      // 退出登录发送来的消息
      if(msg === 'userOutLoginTrue'){
        this.userInfo = this.$options.data().userInfo
        this.userSetInfo = ''
      }
    })
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.documentElement.clientWidth
      })()
    }
  },
  methods: {
    changeCheckbox(e){
      // console.log(this.payChecked)
      let _this = this
      // console.log(_this.userInfo.isLogin)
      if(!_this.userInfo.isLogin){
        setTimeout(()=>{
          _this.payChecked = false
        },200)
        _this.openLogin()
      }else {
        _this.payLoading = true
        // 清除定时器
        let info = {
          close: true
        }
        this.$emit('downloadOffWay',info)
        // 做抵扣金额的操作
        let data = {
          id: _this.dialogOrderDetail.id,
          use: _this.payChecked
        }
        _this.postUserXydou(data)
      }
    },
    showDrawer(param) {
      if(localStorage.getItem('userInfo')){
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      }
      if(localStorage.getItem('userSetInfo')){
        this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
      }
      this.title = param.title
      this.dialogOrderDetail = param.info
      // 如果支付金额为 0 显示支付按钮
      this.payBtn = Number(this.dialogOrderDetail.price) === 0
      this.payLoading = false
      this.centerDialogVisible2 = param.show
    },
    handleClose() {
      this.title = ''
      this.dialogOrderDetail = ''
      this.payBtn = false
      this.payChecked = false
      let info = {
        showState: false,
        payState: false
      }
      this.$emit("downloadOff", info)
      this.centerDialogVisible2 = false
    },
    // 去充值
    openRecharge(){
      // console.log(this.userInfo)
      if(this.userInfo.token){
        // let info = {
        //   close: true
        // }
        // this.$emit('downloadOffWay',info)
        // this.handleClose()
        this.$refs.Wallet.getXydouList(this.userInfo.token,this.userSetInfo.balance)
      }else {
        this.$message.warning('请先登录！')
        this.openLogin()
      }
    },
    // 登录
    openLogin(){
      this.$refs.showLogin.openLogin()
    },
    // acceptInfo(msg){
    //   this.userInfo = msg
    //   if(this.userInfo.isLogin){
    //     this.getUserDetailVo()
    //   }
    //   this.$emit('downloadUserLogin',true)
    // },
    // 充值成功 刷新用户信息
    getUserSetInfo(args){
      if(args === true){
        this.getUserDetailVo()
      }
    },
    getUserDetailVo(){
      UserPromotApi.getUserDetailVo(this.userInfo.token).then(res => {
        this.userSetInfo = res.data
        localStorage.setItem('userSetInfo', JSON.stringify(res.data))
        // 传递更新用户信息的消息
        EventBus.$emit("userSetInfo", 'userInfoTrue')
      }).catch(() => {
      })
    },
    postBalancePay(){
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      CommonApi.postBalancePay(this.dialogOrderDetail.id,this.userInfo.token,key,time,infoMd5).then(res => {
        // console.log(res.data)
        this.$message.success('支付成功')
        let info = {
          id: this.dialogOrderDetail.id,
          payState: true
        }
        this.$emit('downloadOnWay',info)
        // this.handleClose()
        this.getUserDetailVo()
      }).catch(() => {
      })
    },
    postUserXydou(data){
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = _this.$md5(setOf)
      CommonApi.postUserXydou(data,_this.userInfo.token,key,time,infoMd5).then(res => {
        // console.log(res.data)
        // 刷新订单信息
        let info = {
          state: true
        }
        this.$emit('downloadDeduction',info)
      }).catch(() => {
      })
    },
    // 移动端 调取支付
    openPay() {
      let _this = this
      // let order = localStorage.getItem('orderId')
      if(_this.payBtn){
        return _this.postBalancePay()
      }
      if (this.isWeChatBrowser()) {
        window.location.href = `/prod-admin-api/paper/api/public/pay/${_this.dialogOrderDetail.id}`
      } else {
        if (_this.payMethod == 1) {
          // 支付宝支付
          window.location.href = `/prod-admin-api/paper/api/public/h5/pay/${_this.dialogOrderDetail.id}/1`
        } else {
          // 微信支付
          window.location.href = `/prod-admin-api/paper/api/public/h5/pay/${_this.dialogOrderDetail.id}/2`
        }
      }
    },
    // 判断是否是微信浏览器
    isWeChatBrowser() {
      const userAgent = navigator.userAgent.toLowerCase()
      const isWx = /micromessenger/.test(userAgent)
      if (isWx) {
        this.payMethod = '2'
      }
      return isWx
    },
  },
  computed: {
    preferentialPrice(){
      let num = this.dialogOrderDetail.showPrice - this.dialogOrderDetail.price
      num = num.toFixed(2)
      return num
    }
  }
}
</script>
<style scoped lang="less">
.way-dialog{
  /deep/ .el-dialog{
    width: 720px;
    min-width: 720px !important;
    border-radius: 15px;
    .el-dialog__close {
      font-size: 24px;
    }
  }
}
.pay-hot{
  width: 33%;
  min-width: 240px;
  margin: 0 auto;
  padding: 5px 0;
  border-radius: 5px;
  text-align: center;
  color: #e31c1c;
  font-size: 15px;
  font-weight: bold;
  border: 1px dashed #ffc4c4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  i{
    img{
      width: 18px;
      height: 18px;
    }
  }
}
.pay-title {
  margin: 30px 0 0 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
  }
}
.pay-cont {
  padding: 30px 0;
  //margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .pay-img {
    position: relative;
    border-radius: 15px;
    border: none;

    > div {
      padding: 10px;
      border: 1px solid #e6e6e6;

      img {
        width: 124px;
        height: 124px;
        padding: 0;
        margin: 0;
        border: 0;
        background-color: #ffffff;
      }
    }

    > div:before, > div:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
    }

    > div:before {
      bottom: 0;
      left: 0;
      border-bottom: 2px solid #36b7e6;
      border-left: 2px solid #36b7e6;
    }

    > div:after {
      bottom: 0;
      right: 0;
      border-bottom: 2px solid #36b7e6;
      border-right: 2px solid #36b7e6;
    }
  }

  .pay-img:before,
  .pay-img:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
  }

  .pay-img:before {
    top: 0;
    left: 0;
    border-top: 2px solid #36b7e6;
    border-left: 2px solid #36b7e6;
  }

  .pay-img:after {
    top: 0;
    right: 0;
    border-top: 2px solid #36b7e6;
    border-right: 2px solid #36b7e6;
  }

  .pay-text {
    width: 450px;
    padding-left: 30px;

    .txt-tip{
      margin-bottom: 10px;
    }

    .price-box {
      padding: 10px 0;
      display: flex;
      align-items: center;

      .price-left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        >div{
          > span {
            font-size: 28px;
            font-weight: bold;
            color: #fc5151;
          }
          .t {
            margin-right: 6px;
            font-size: 16px;
            font-weight: initial;
          }
          .ck{
            margin-left: 6px;
            line-height: 16px;
            /deep/ .el-checkbox__label{
              padding-left: 6px;
            }
            /deep/ .el-checkbox__input.is-checked+.el-checkbox__label{
              color: #3c3c3c;
            }
            span {
              color: #fc5151;
            }
          }
          .yj {
            font-size: 14px;
            color: #9ea2a9;
            font-weight: initial;
            font-style: italic;
            text-decoration: line-through;
          }
          .yh {
            margin-left: 6px;
            font-size: 14px;
            color: rgb(204, 126, 32);
            font-weight: initial;
          }

          .cz{
            margin: 0 8px;
            font-size: 14px;
            font-weight: initial;
            color: #6e83f7;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            .qian16{
              display: block;
              width: 20px;
              height: 20px;
              line-height: 20px;
              margin-right: 3px;
              text-align: center;
              border-radius: 20px;
              border: 1px solid #6e83f7;
              font-size: 14px;
              color: #6e83f7;
              font-style: initial;
              text-decoration: none;
            }
          }
          .cz:hover{
            text-shadow: 0 0 5px rgba(60, 90, 251, 0.5);
          }
        }
        .price-foot{
          padding-top: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
    .show-jcc{
      justify-content: center;
    }

    .pay-tip {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }

      p {
        span {
          font-size: 14px;
          color: #409EFF;
        }
      }
    }
    .pay-btn2{
      margin-top: 30px;
      text-align: center;
    }

  }
}

.show-drawer-box{
  /deep/ .el-drawer__header{
    margin-bottom: 10px !important;
  }
  /deep/ .el-drawer{
    height: 50% !important;
    border-radius: 15px 15px 0 0;
  }
  .show-drawer-item{
    position: relative;
    width: 94%;
    margin: 0 auto;
    padding-top: 20px;
    height: 100%;
    .pay-title{
      margin: 0 5%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      img{
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      h3{
        font-size: 16px;
        font-weight: bold;
      }
    }
    .pay-h2{
      padding: 20px 0;
      text-align: center;
    }
    .pay-check{
      width: 90%;
      margin: 10px auto 0 auto;
      background-color: #ffffff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 1px solid #e4e5e7;
      >div{
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        img{
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
        span{
          font-size: 12px;
        }
        .pay-radio{
          margin-left: auto;
        }
      }
      >div:last-child{
        border-top: 1px solid #e4e5e7;
      }
    }
    .pay-btn{
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      margin-top: 30px;
      background-color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .pay-txt{
        font-size: 12px;
        color: #3c3c3c;
        span{
          margin-right: 5px;
          font-size: 18px;
          font-weight: bold;
          color: #fc5151;
        }
        i{
          font-size: 12px;
          color: #9c9c9c;
          text-decoration: line-through;
        }
      }
    }
    .txt-h5-ck{
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      line-height: 16px;
      /deep/ .el-checkbox__label{
        padding-left: 6px;
        font-size: 12px;
      }
      /deep/ .el-checkbox__input.is-checked+.el-checkbox__label{
        color: #3c3c3c;
      }
      .txt-h5-p{
        font-size: 12px;
        color: #fc5151;
      }
      .cz{
        margin: 0 8px;
        font-size: 10px;
        font-weight: initial;
        color: #6e83f7;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        .qian16{
          display: block;
          width: 14px;
          height: 14px;
          line-height: 12px;
          margin-right: 2px;
          text-align: center;
          border-radius: 14px;
          border: 1px solid #6e83f7;
          font-size: 11px;
          color: #6e83f7;
          font-style: initial;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
